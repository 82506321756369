<template>
  <div id="course-management">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <div class="d-flex justify-space-between">
            <h2 class="mb-10">Daily Absent Teacher</h2>
            <v-btn color="primary" @click="viewAll()"
              >View Teacher leave of absence request list</v-btn
            >
          </div>
          <v-row class="mb-2">
            <v-col md="5">
              <div class="d-flex">
                <v-btn text color="secondary" @click="prevDate">
                  <v-icon> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn outlined color="secondary" @click="setToday"> Today </v-btn>
                <v-btn text color="secondary" @click="nextDate">
                  <v-icon> mdi-chevron-right </v-icon>
                </v-btn>

                <v-select
                  :items="centerList"
                  item-text="location"
                  item-value="locationID"
                  append-icon="keyboard_arrow_down"
                  outlined
                  :class="'select-input'"
                  dense
                  label="Center"
                  multiple
                  hide-details
                  placeholder="Center"
                  v-model="locationIDs"
                  @change="changeLocation"
                  :style="{ marginRight: '10px', overflow: 'hidden', maxHeight: '30px' }"
                >
                </v-select>

                <v-text-field
                  type="date"
                  v-model="date"
                  @click="isChangeDateField = true"
                  outlined
                  dense
                  hide-details
                />
                <div class="mt-2 ml-2">{{ dayOfWeek }}</div>
              </div>
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
            class="my-10"
          ></v-progress-linear>
          <v-data-table
            v-if="!isLoading"
            :headers="headers"
            :items="filtered"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-header
            hide-default-footer
            @page-count="pageCount = $event"
            class="elevation-0"
            fixed-header
            height="60vh"
          >
            <template v-slot:header="{ props }">
              <thead class="v-data-table-header">
                <tr>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    role="columnheader"
                    scope="col"
                    aria-label="No"
                    class="text-start font-weight-bold"
                    :style="'width:' + header.width + '; min-width:' + header.width + ';'"
                  >
                    <span>{{ header.text }}</span>
                  </th>
                </tr>
              </thead>
              <tr class="fixed-row-filter">
                <th v-for="header in props.headers" :key="header.text">
                  <div v-if="filters.hasOwnProperty(header.value)">
                    <Autocomplete
                      v-if="filters.hasOwnProperty(header.value)"
                      @setFilter="setFilter"
                      :styleBorder="'border-bottom'"
                      :keyFilter="header.value"
                      :selectedInit="filters[header.value]"
                      :listItem="groupColumnValueList(header.value)"
                    />
                  </div>
                </th>
              </tr>
            </template>

            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.center }}</td>
                <td :class="' link'" @click="viewDetailClass(item.classID)">
                  <p :class="'w-120'">{{ item.classCode }}</p>
                </td>

                <td >
                  {{ item.classTime }}
                </td>
                <td :class="'  link'" @click="viewProfileByUserId(item.qc_userID)">
                  {{ item.qc }}
                </td>
                <td :class="'  link'" @click="viewProfileByUserId(item.teacher_userID)">
                  {{ item.teacher }}
                </td>

                <td :class="'  link'" @click="viewProfileByUserId(item.teacher_userID)">
                  {{ item.coverTeacher }}
                </td>

                <td>
                  <div class="d-flex align-center actionIcon">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          v-bind="attrs"
                          v-on="on"
                          v-if="item.lessonNote === '' || item.lessonNote === null"
                          :style="{ width: '18px' }"
                          @click="openEditNote(item)"
                          src="../../assets/images/notes (1).svg"
                          alt=""
                        />
                        <img
                          v-bind="attrs"
                          v-on="on"
                          v-else
                          :style="{ width: '18px' }"
                          @click="openEditNote(item)"
                          src="../../assets/images/notes.svg"
                          alt=""
                        />
                      </template>
                      <span v-if="item.lessonNote === '' || item.lessonNote === null"
                        >Create note</span
                      >
                      <span v-else>View note</span>
                    </v-tooltip>
                  </div>
                  <div v-show="overlay" :class="'h'">
                    <div class="card-i">
                      <v-card
                        :style="{ boxShadow: 'none' }"
                        light
                        :min-width="screen < 700 ? '500' : '600'"
                      >
                        <v-card-title class="text-h5" :style="{ fontWeight: 700 }">
                          Lesson notes
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-textarea
                          :v-model="noteText"
                          name="input-7-1"
                          label="Add lesson notes"
                          :disabled="!isEdit"
                          @change="noteOnChange"
                          :value="noteText"
                          outlined
                          dense
                          height="30vh"
                          class="abc mt-3 ml-5 mr-5"
                          id="abcd"
                        ></v-textarea>
                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <div :class="'mb-4'" class="btnNote">
                            <v-btn elevation="2" large @click="overlay = false">Cancel</v-btn>
                            <v-btn
                              elevation="2"
                              large
                              color="primary"
                              @click="saveNote"
                              v-if="isEdit === true"
                              >Save</v-btn
                            >
                            <v-btn elevation="2" large color="primary" @click="btn_Edit" v-else
                              >Edit</v-btn
                            >
                          </div>
                        </v-card-actions>
                      </v-card>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <div v-if="!isLoading" color="#fff" class="w-100 pl-10 pr-10">
      <v-row>
        <v-col md="2">
          <v-select
            outlined
            hide-details
            dense
            :value="itemsPerPage"
            :items="[20, 50, 100, 200]"
            @change="itemsPerPage = parseInt($event, 10)"
            style="height: 32px"
          ></v-select>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-end">
          <h3 v-if="filtered.length != 1">Total: {{ filtered.length }} classes</h3>
          <h3 v-else>Total: {{ filtered.length }} class</h3>
        </v-col>
        <v-col md="6" class="d-flex justify-end">
          <v-pagination
            v-model="page"
            :length="pageCount"
            elevation="0"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center">
      <v-dialog v-model="errorDialog" persistent width="350">
        <v-card class="mx-auto pa-10">
          <div class="text-center">
            <h1>Error!</h1>
            <p>{{ message.messages.UNKNOWN_ERROR }}</p>
          </div>
          <div class="text-center">
            <v-btn color="error" elevation="0" @click="back()" outlined> Back </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { class_managerment } from '@/api/class-management.js'
// import Multiselect from "vue-multiselect";
import dayjs from 'dayjs'
import message from '@/components/Utils/message'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
export default {
  components: {
    // Multiselect,
    Autocomplete,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      headers: [
        {
          text: 'No',
          align: 'start',
          value: 'no',
          sortable: false,
        },
        {
          text: 'Center',
          value: 'center',
          sortable: false,
        },
        {
          text: 'Class code',
          value: 'classCode',
          sortable: false,
        },

        {
          text: 'Class time',
          value: 'classTime',
          sortable: false,
        },

        {
          text: 'QC',
          value: 'qc',
          sortable: false,
        },
        {
          text: 'Absent teacher',
          value: 'teacher',
          sortable: false,
        },
        {
          text: 'Cover teacher',
          value: 'coverTeacher',
          sortable: false,
        },

        { text: 'Note', value: 'action', width: '7%', sortable: false },
      ],
      classList: [],
      filters: {
        classCode: [],
        center: [],
        qc: [],
        teacher: [],
        coverTeacher: [],
        classTime: [],
      },
      noteText: '',
      isEdit: false,
      overlay: false,
      myUserId: 0,
      scheduleIDEdit: 0,
      date: '',
      weekDay: '',
      dayOfWeek: '',
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      currentDate: new Date().getDate(),
      isChooseDate: false,
      recordID: null,
      centerList: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      locationIDs: [],
      dateOfDailyClasses: localStorage.getItem('dateOfDailyClasses'),
      isBackFromDetailDailyClasses: localStorage.getItem('isBackFromDetailDailyClasses') || null,
      isChangeDateField: false,
      errorDialog: false,
      message: message,
    }
  },
  computed: {
    filtered() {
      return this.classList.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  created() {
    if (!this.isBackFromDetailDailyClasses) {
      this.date =
        this.currentYear +
        '-' +
        this.checkTime(this.currentMonth) +
        '-' +
        this.checkTime(this.currentDate)
    } else {
      this.date = this.dateOfDailyClasses
    }
    this.weekDay = new Date(this.date).getDay()
    this.dayOfWeek = this.formatWeekDay(this.weekDay)
    this.centerList.forEach(x => {
      this.locationIDs.push(x.locationID)
    })

    this.myUserId = JSON.parse(localStorage.getItem('currentUser')).userInfo.fullName
    this.setToday()
  },
  watch: {
    date: async function (value) {
      if (value && this.isChangeDateField) {
        this.weekDay = new Date(this.date).getDay()
        this.dayOfWeek = this.formatWeekDay(this.weekDay)
        this.classList = []
        this.getAbsentTeacher()
      }
    },
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },

    async init() {
      this.isChangeDateField = false
      this.classList = []
      this.weekDay = new Date(this.date).getDay()
      this.dayOfWeek = this.formatWeekDay(this.weekDay)
      this.getAbsentTeacher()
    },
    formatWeekDay(weekDay) {
      switch (weekDay) {
        case 0:
          weekDay = 'Sunday'
          break
        case 1:
          weekDay = 'Monday'
          break
        case 2:
          weekDay = 'Tuesday'
          break
        case 3:
          weekDay = 'Wednesday'
          break
        case 4:
          weekDay = 'Thursday'
          break
        case 5:
          weekDay = 'Friday'
          break
        case 6:
          weekDay = 'Saturday'
          break
      }
      return weekDay
    },
    viewAll() {
      this.$router.push({ name: 'teacher-leave-of-absence-request' })
    },
    checkTime(i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    groupColumnValueList(val) {
      return this.classList.map(d => d[val]).filter(y => y)
    },
    toggle(value) {
      this.$nextTick(() => {
        if (
          this.filters[value].length ==
          this.classList
            .map(d => d[value])
            .reduce(function (accumulator, element) {
              if (accumulator.indexOf(element) === -1) {
                accumulator.push(element)
              }
              return accumulator
            }, []).length
        ) {
          this.filters[value] = []
        } else {
          this.filters[value] = this.classList
            .map(d => d[value])
            .reduce(function (accumulator, element) {
              if (accumulator.indexOf(element) === -1) {
                accumulator.push(element)
              }
              return accumulator
            }, [])
            .slice()
        }
      })
    },
    viewProfileByUserId(userId) {
      if (userId)
        this.$router.push({
          name: 'account-management-view-account',
          params: {
            userId: userId,
          },
        })
    },
    async setToday() {
      this.date =
        this.currentYear +
        '-' +
        this.checkTime(this.currentMonth) +
        '-' +
        this.checkTime(this.currentDate)
      this.weekDay = new Date(this.date).getDay()
      this.dayOfWeek = this.formatWeekDay(this.weekDay)
      this.getAbsentTeacher()
    },
    viewDetailClass(classID) {
      localStorage.setItem('dateOfDailyClasses', new Date())
      localStorage.removeItem('isBackFromDetailDailyClasses')
      this.$router.push({
        name: 'class-management-detail',
        query: { classID: classID },
      })
    },
    async prevDate() {
      var yearOfPrevDate = this._dayjs(this.date).format('YYYY')
      var monthOfPrevDate = this._dayjs(this.date).format('MM')
      var dateOfPrevDate = this._dayjs(this.date).format('DD')
      if (dateOfPrevDate != '01') {
        this.date =
          yearOfPrevDate + '-' + monthOfPrevDate + '-' + this.checkTime(dateOfPrevDate - 1)
        this.weekDay = new Date(this.date).getDay()
        this.dayOfWeek = this.formatWeekDay(this.weekDay)
      } else if (dateOfPrevDate == '01' && monthOfPrevDate == '01') {
        this.date = yearOfPrevDate - 1 + '-' + '12' + '-' + '31'
        this.weekDay = new Date(this.date).getDay()
        this.dayOfWeek = this.formatWeekDay(this.weekDay)
      } else {
        this.date =
          yearOfPrevDate +
          '-' +
          this.checkTime(monthOfPrevDate - 1) +
          '-' +
          this.checkTime(this._dayjs(new Date(yearOfPrevDate, monthOfPrevDate - 1, 0)).format('DD'))
        this.weekDay = new Date(this.date).getDay()
        this.dayOfWeek = this.formatWeekDay(this.weekDay)
      }
      this.getAbsentTeacher()
    },
    async nextDate() {
      var yearOfPrevDate = this._dayjs(this.date).format('YYYY')
      var monthOfPrevDate = this._dayjs(this.date).format('MM')
      var dateOfPrevDate = this._dayjs(this.date).format('DD')
      var biggestDateOfMonth = this._dayjs(new Date(yearOfPrevDate, monthOfPrevDate, 0)).format(
        'DD'
      )
      if (dateOfPrevDate < biggestDateOfMonth) {
        this.date =
          yearOfPrevDate +
          '-' +
          monthOfPrevDate +
          '-' +
          this.checkTime(parseInt(dateOfPrevDate) + 1)
        this.weekDay = new Date(this.date).getDay()
        this.dayOfWeek = this.formatWeekDay(this.weekDay)
      } else if (dateOfPrevDate == '31' && monthOfPrevDate == '12') {
        this.date = parseInt(yearOfPrevDate) + 1 + '-' + '01' + '-' + '01'
        this.weekDay = new Date(this.date).getDay()
        this.dayOfWeek = this.formatWeekDay(this.weekDay)
      } else {
        this.date =
          yearOfPrevDate + '-' + this.checkTime(parseInt(monthOfPrevDate) + 1) + '-' + '01'
        this.weekDay = new Date(this.date).getDay()
        this.dayOfWeek = this.formatWeekDay(this.weekDay)
      }
      this.getAbsentTeacher()
    },
    //take note
    async getAbsentTeacher() {
      this.isLoading = true
      await class_managerment.getDailyAbsentTeacher(this.locationIDs, this.date).then(res => {
        if (res) {
          this.classList = res
          this.isLoading = false
        }
      })
    },
    noteOnChange(value) {
      this.noteText = value
    },
    async reload() {
      this.isLoading = true
      this.overlay = false
      this.getAbsentTeacher()
      this.isLoading = false
    },
    saveNote() {
      class_managerment.updateNote(this.noteText, this.scheduleIDEdit)
      this.overlay = false
      this.reload()
    },

    btn_Edit() {
      this.isEdit = true
    },
    openEditNote(item) {
      if (item.lessonNote == null || item.lessonNote == '') this.isEdit = true
      else this.isEdit = false
      this.overlay = true
      this.scheduleIDEdit = item.scheduleID
      this.noteText = item.lessonNote
    },
    btnEdit(data) {
      if (typeof data === 'string') {
        class_managerment.updateNote(data, this.scheduleIDEdit)
      }
    },
    //end take note
    back() {
      window.history.back()
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss">
.select-input {
  margin-right: 5px;
}

.select-input input {
  width: 130px;
}
.link {
  cursor: pointer;

  color: #427df2 !important;
}

.link:hover {
  text-decoration: underline;
  font-weight: 700;
}
.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  left: 0;
}

.card-i {
  width: 500px;
  position: absolute;
  top: 50%;

  left: 50%;
  transform: translate(-50%, -50%);
}

.btnNote {
  float: right;
  margin-top: 20px;
}

.btnNote button {
  margin-left: 20px;
  min-width: 150px !important;
}
</style>
